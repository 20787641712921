<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Pra Project</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <praProject-grid ref="grid" :key="gridReload" :deleteClick="deleteClick" :editClick="editClick" :statusClick="statusClick" :commissionClick="commissionClick" />
                <praProject-form ref="praProjectForm" :reload="reload"/>
                <praProject-status-form ref="PraProjectStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import praProjectService from './Script/PraProjectServices.js';
import PraProjectGrid from '../PraProject/Grid/PraProjectGrid.vue';
import PraProjectForm from '../PraProject/Component/PraProjectForm.vue';
import PraProjectStatusForm from '../PraProject/Component/PraProjectStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'PraProject',
    components: {
        'praProject-grid': PraProjectGrid,
        'praProject-form': PraProjectForm,
        'praProject-status-form': PraProjectStatusForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Pra Project');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.praProjectForm.addClick();
        },
        editClick(data, view){
            this.$refs.praProjectForm.editClick(data, view);
        },
        commissionClick(id){
            this.$router.push({ name: 'Komisi', params: { id:id, view:'Pra Project' } });
        },
        deleteClick(id){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : id
                }

                if (result.isConfirmed == true) {
                    praProjectService.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.PraProjectStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_preproject', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnEnd").on('click', function() {
                    vue.$refs.grid.changeStatus('End');
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnCancel").on('click', function() {
                    vue.$refs.grid.changeStatus('Cancel');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnKomisi").on('click', function() {
                    vue.$refs.grid.changeStatus('Komisi');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>