<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Pra-Project Approver</h2>
            </CCardHeader>
            <CCardBody>
                <pra-project-approver-grid ref="grid" :key="gridReload" :viewClick="viewClick" :approveClick="approveClick"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import praProjectApproverGrid from '../PraProjectApprover/Grid/PraProjectApproverGrid.vue';
import praProjectApproverServices from './Script/PraProjectApproverScript.js';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'PraProjectApprover',
    components: {
        'pra-project-approver-grid': praProjectApproverGrid,
    },
    async mounted () {
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        viewClick(id){
            this.$router.push({ name: 'Komisi', params: { id:id, view:'Pra Project Approver' } });
        },
        async approveClick(id){
            var data = await praProjectApproverServices.getDetailPraProject(id);

            const preProjectData = {
                pre_project_code: data.pre_project_code,
                pre_project_date: data.pre_project_date,
                pre_project_name: data.pre_project_name,
                status: data.status,
                sales_id: data.sales_id,
                areacode: data.areacode,
                notes: data.notes,
                valuation: data.valuation,
                recommended_percentage: data.recommended_percentage,
                recommended_amount: data.recommended_amount,
                commission_amount: data.commission_amount,
                is_approved: true
            };
            
            this.$loading(true);

            const variables = {
                id : id,
                data : preProjectData
            };

            this.$swal(sweet_alert.approve).then((result) => {
                if (result.isConfirmed == true) {
                    praProjectApproverServices.approveQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successApprove, "success");
                        this.$loading(false);
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.errorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                        this.$loading(false);
                    })
                }
                else {
                    this.$loading(false);
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>